body {
  font-size: 16px !important;
  font-family: arial, sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  color: #000000;
}

/* roboto-condensed-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto-condensed-v27-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

div,
span,
p {
  transition: all 0s ease;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size: 1em !important;
  line-height: 1.42;
  tab-size: 4;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
}

label {
  transition: all 1.1s ease;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size: 1em;
  line-height: 1.5em;
  padding: 0 2px;
  text-transform: none;
  margin-bottom: 2px;
  color: inherit;
  background-color: transparent;
}

input {
  color: inherit !important;
  background-color: white !important;
  font-size: 1em;
  width: 100%;
}

textarea {
  color: inherit !important;
  background-color: white !important;
  font-size: 1em;
  width: 100%;
}

.p-inputtext {
  padding: 2px;
  font-size: 1em;
  font-family: inherit;
}

.p-multiselect .p-multiselect-label {
  padding: 2px;
  font-size: 1em;
}

.p-button {
  width: 100%;
}

.required-label {
  color: red !important;
}

.info-button {
  width: 2em !important;
  min-width: 2em !important;
  margin-top: -2px;
  display: inline-flex;
  height: 1.5em;

  .info-img-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -2px;

    .info-img {
      transform: scale(1.2);
      left: 50%;
      margin-left: -50%;
      position: relative;
      padding: 4px 2px 2px;
      color: blue;
    }

  }

  .info-bubble {
    position: absolute;
    margin-block-start: 2px;
    margin-top: 2px;
    z-index: 1000;
    max-width: 15em !important;
    margin-left: 2em !important;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
  }

}

.info-button-anzpac {
  width: 2em !important;
  min-width: 2em !important;
  margin-top: -2px;
  display: inline-flex;
  height: 1.5em;

  .info-img-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -2px;

    .info-img {
      transform: scale(1.2);
      left: 50%;
      margin-left: -50%;
      position: relative;
      padding: 4px 2px 2px;
      color: #626262;
    }

  }

  .info-bubble {
    position: absolute;
    margin-block-start: 2px;
    margin-top: 2px;
    z-index: 1000;
    max-width: 15em !important;
    margin-left: 2em !important;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
    color: black;
  }

}


.info-button-anzpacwhite {
  width: 2em !important;
  min-width: 2em !important;
  margin-top: -2px;
  display: inline-flex;
  height: 1.5em;

  .info-img-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: -2px;

    .info-img {
      transform: scale(1.2);
      left: 50%;
      margin-left: -50%;
      position: relative;
      padding: 4px 2px 2px;
      color: white;
    }

  }

  .info-bubble {
    position: absolute;
    margin-block-start: 2px;
    margin-top: 2px;
    z-index: 1000;
    max-width: 15em !important;
    margin-left: 2em !important;
    background-color: white;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
    color: black;
  }

}

.anzpazbt-1 {
  border-top: 1px solid #626262 !important;
}

.anzpazbl-1 {
  border-left: 1px solid #626262 !important;
}

.anzpazbr-1 {
  border-right: 1px solid #626262 !important;
}

.ion-page-hidden {
  visibility: hidden !important;
  display: none;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0px;
}

.header-container {
  height: 6em !important;
}

.body-container {
  width: 85%;
  margin-left: 7.5% !important;
  margin-right: 7.5% !important;
}

.headercontent {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  padding-top: 8px !important;
  flex: 1 !important;
  margin-left: 5% !important;
}

.page-blocker-container {
  height: calc(100vh - 1em);
  width: calc(100vw - 1em);
  color: #ffffff;
  background-color: #000000;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  z-index: 999;
  position: relative;
  opacity: 0.4;
}

.page-loading-container {
  align-items: center;
  align-content: center;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  .app-page-blocker-text {
    color: #488aff;
    font-size: 4em !important;
    font-weight: bold;
  }

  .page-blocker-text {
    color: #488aff;
    font-size: 2em !important;
    font-weight: bold;
  }
}

.page-content-container {
  height: calc(100%);
  width: calc(100%);
  color: inherit;
  background-color: transparent;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

@media (min-width: 1025px) {
  .headercontent {
    margin-left: 15% !important;
    padding-top: 0px !important;
  }
}

@media (max-width: 1024px) and (min-width: 600px) {
  .headercontent {
    margin-left: 3% !important;
  }
}



.left-header-padding {
  width: 7.5% !important;
  min-width: 7.5% !important;
}

.brand-logo {
  height: 4em !important;
  margin: 1em 0.3em;
}

.rm-widget-container {
  width: 100%;
}

#rm-iframe {
  @media screen and (max-width: 1200px) {
    height: 100vh;
  }
}

.iframeborder {
  border: 10px solid #14908E;
  border-radius: 10px;
  line-height: 0;
}

.rm-arl-icon {
  width: 100%;
}

.component-layout-main {
  width: calc(100%);
  height: calc(100%);
  overflow: auto;
}

.component-layout {
  width: calc(100%);
  height: calc(100%);
}

.component-content {
  width: calc(100%);
  height: calc(100%);
  overflow: auto;
}

.component-header-menu {
  height: 5em;
}

.component-content.component-with-header-menu {
  height: calc(100% - 5em);
}

.content-container {
  border: 0;
  margin: 0;
  padding: 5px 0px;
  height: 100%;
  height: calc(100%);
  width: 100%;
  width: calc(100%);
  display: block;
  overflow: auto;
  color: inherit;
  background-color: transparent;
  position: sticky;
}

.form-content {
  overflow: auto;
  padding-right: 0px;
  height: calc(100%) !important;
}

.nav-link {
  color: black;
}

.nav-link:hover {
  font-weight: 500;
  color: #14908E;
}

.yellow-border:hover {
  font-weight: 500;
  color: #14908E;
  border-bottom: #F7DD72 solid 3px;
  padding-bottom: 5px;
}

/* #region for handling of p-dataview */
.dataview-header {
  background-color: dimgrey;
  color: white;
}

.dataview-column-title {
  font-size: 1.2em !important;
  font-family: inherit !important;
  padding: 5px !important;
}

.dataview-row {
  border: 0px solid gray;
  border-width: 0px;
}

.dataview-row:nth-child(even) {
  background: white;
}

.dataview-row:hover {
  background: #dff5d5;
}

p-dataview {
  height: calc(100%);
  width: 100%;
  color: black;

  .p-dataview.p-component {
    height: calc(100%);
    font-family: arial, sans-serif;
  }

  .p-dataview .p-dataview-header {
    background-color: transparent;
    color: inherit;
    border: 0 none;
    border-top: 0 none;
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-width: 0px;
    padding: 0em;
  }

  .p-dataview .p-dataview-content {
    height: calc(100%);
    background-color: transparent;
    color: inherit;
    border: 0 none;
    border-top: 0 none;
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-width: 0px;
    padding: 0em;

    .p-grid {
      height: calc(100%);
      color: inherit;
      display: -webkit-flex;
      -webkit-flex-wrap: wrap;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 0em;
      padding-right: 0em;
      padding-top: 0em;
      padding-bottom: 0em;
      align-content: flex-start;
      scroll-behavior: smooth;
      overscroll-behavior: auto contain;
      overflow: auto;
    }

  }

  .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div {
    border: 0px solid gray;
    border-width: 0px;
  }

  .p-dataview.p-dataview-list .p-dataview-content>.p-grid>div:nth-child(even) {
    background: white;
  }

  .p-dataview .p-dataview-footer {
    min-height: 40px;
    padding: 0.5em;
    background-color: transparent;
    color: inherit;
  }

  .p-paginator {
    background-color: transparent;
    color: black;
    padding: 0.5em;
    border: 0 none;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a:not([href]):not([tabindex]) {
      color: inherit;
    }
  }

  .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    color: black !important;
    height: 3em !important;
    min-height: 3em !important;
    min-width: 3em !important;
    line-height: 1.5em;
    border: 0 none;
    padding: 0;
    margin: 0;
    vertical-align: top;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-size: 1em;
  }

  .p-paginator-pages .p-paginator-page.p-highlight {
    color: inherit;
    background-color: transparent;
  }

  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    color: black;
    background-color: transparent;
    height: 1.5em;
    min-height: 1.5em;
    width: 1.5em;
    min-width: 1.5em;
    line-height: 1.5em;
    border: 0 none;
    padding: 0;
    margin: 0;
    vertical-align: top;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-size: 1.5em;
  }

}

#submissionList {
  .p-paginator-bottom.p-paginator.p-component {
    background-color: #EBF2F8 !important;
  }

}

.mobile {
  .grid-border-round {
    .p-dataview {
      .p-grid {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

    }
  }
}

.grid-border-round {
  .p-dataview.p-component.p-dataview-list {
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .p-dataview .p-dataview-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .p-paginator {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

}

.admin-dataView.p-dataview.p-component.p-dataview-list {
  border: 1px solid gray;

  .p-dataview-content {
    height: calc(100% - 4em);
    overflow: auto;
  }
}

.admin-dataView.dataView-with-header.p-dataview.p-component.p-dataview-list {
  .p-dataview-content {
    height: calc(100% - 7em);
  }
}

.admin-dataView-nopagination.p-dataview.p-component.p-dataview-list {
  border: 1px solid gray;

  .p-dataview-content {
    height: calc(100%);
    overflow: auto;
  }

}

/* #endregion for handling of p-dataview */
/* Start of dropdown */
.dropdown-menu {
  border: #F7DD72 solid 3px;
  border-radius: 0px 10px 10px 10px;
  display: none;
  position: absolute;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #F8F9FA !important;
}

.dropdown-menu:hover .dropdown-item {
  background-image: none;
  background-color: #F8F9FA !important;
}

.navbar-nav>li>.dropdown-menu {
  background-color: #F8F9FA !important;
}

.dropdown-toggle::after {
  border: none;
  font-family: 'FontAwesome';
  content: "\f078";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: bold;
  font-size: .5em;
  color: #10908d;
}

/* End of dropdown */

.p-button {
  min-height: 3em;
  min-width: 3em;
  font-size: 1em;
  padding: 0.5em 0.5em;
  background: #0B605E !important;
}

p-panelmenu {
  width: 100%;
}

.p-panelmenu-panel {
  width: 100%;
  box-shadow: unset !important;
  border: 0 none;
}

.p-panelmenu-header {
  padding: 10px;
}

/* region p-menu */
.p-menubar {
  padding: 0.5em !important;
}

.p-menubar,
.p-menubar-root-list {
  border: 0 none;
  background: transparent;
  color: black;
  padding-left: 1em;
}

.show-side-menu {
  .right {
    .p-menu.p-component.p-menu-overlay {
      right: 1.3em;
      left: unset !important;
    }

  }
}

.p-menu {
  background: white !important;
  color: black;
}

.p-menubar-button {
  color: black;
}

.p-menubar .p-menubar-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
  color: black;
  background: transparent;
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
  color: black;
  background: transparent;
}

.p-menuitem,
.p-menuitem-content {
  color: black !important;
  background: transparent !important;
}

.p-menuitem-text {
  color: black !important;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background-color: white !important;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #0B605E !important;
  background-color: white !important;
}


.p-menubar .p-menuitem-link .p-submenu-icon {
  color: black !important;
  margin-left: 15px;
}

p-panelmenu-header-action a {
  color: black !important;
}

p-icon p-submenu-icon a {
  color: black !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: black !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled) .p-submenu-icon {
  color: black !important;
}

a.p-panelmenu-header-action {
  color: black !important;
}

p-icon p-submenu-icon {
  color: black !important;
}

.p-icon-wrapper {
  order: 1;
  margin-inline-start: auto;
}

/* endregion p-menu */
/* region p-dialog */
.p-dialog .p-dialog-header {
  background: white;
  color: black;

  button {
    height: 2em !important;
    width: 2em !important;
    min-height: unset;
    min-width: unset;
  }
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: black;
}

.p-dialog-title {
  font-weight: bold !important;
}

.p-dialog .p-dialog-content {
  background: white;
  color: black;
}

/* endregion p-dialog */
/* region p-dropdown */
.p-dropdown {
  width: 100%;
  font-family: inherit;
}


.p-dropdown-label {
  font-family: inherit;
}

.p-element {
  font-family: inherit;
}

/* endregion p-dropdown */
/* region p-multiselect */
.p-multiselect {
  width: 100%;
}

/* endregion p-multiselect */
/* region p-checkbox */
.p-checkbox .p-checkbox-box {
  background: white;
}

/* endregion p-checkbox */
.p-toast-message-text {
  width: calc(80%);
  word-wrap: break-word;
  white-space: pre-line;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #fff;
  color: rgba(0, 0, 0, .87);
  padding: 1.2em;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2em !important;
}

p-editor {
  height: calc(100%);
  width: 100%;
}

.p-editor-container {
  height: calc(100%);
}

p {
  font-family: Arial, sans-serif;
}

.squares {
  background-color: #14908E;
}

.disclaimer {
  background-color: #EBF5EE;
  color: #2F3D48;
  position: relative;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/*   disclaimer div 3d shadow 
.disclaimer:before, .disclaimer:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.disclaimer:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
 End of disclaimer shadow   */

abbr {
  text-decoration: none !important;
}

a {
  color: blue;
  text-decoration: none;
}

.footer {
  color: #2F3D48;
  background-color: #dcdcdc;
}

.big-btn {
  height: 3em;
  min-height: 38px !important;
}

.btn {
  color: #fff;
  background-color: #14908E;
  width: 100%;
  border-radius: 0;
  border: 3px solid #14908E;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.btn:hover {
  color: #14908E;
  background-color: #fff;
  width: 100%;
  border-radius: 0;
  border: 3px solid #14908E;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.btn:active {
  color: #14908E;
  background-color: #fff;
  width: 100%;
  border-radius: 0;
  border: 3px solid #14908E;
  box-shadow: rgb(0 0 0 / 0.0);
}

.footer-links {
  margin-bottom: 0;
}

/* .p-disabled,.p-component:disabled{opacity:.38} */

.button-highlight {
  border: 1px solid black;
  color: white;
  background-color: #14908E !important;
}

.button-highlight.p-disabled {
  opacity: 0.8 !important;
}

.button-round {
  border-radius: 1em;
  padding: 2px;
}

.button-no-padding {
  padding: 0px;
}

.bgc-gray {
  background-color: #EFEFEF !important;
}

.bgc-silver {
  background-color: #D5D5D5 !important;
}

.bgc-green {
  background-color: #BAD9D8 !important;
}

.bgc-lime {
  background-color: #EBF5EE !important;
}

.bgc-amber {
  background-color: #FFDF84 !important;
}

.bgc-yellow {
  background-color: #F7DD72 !important;
}

.bgc-highlightblue {
  background-color: blue !important;
}

.bgc-lightblue {
  background-color: #BFD1E5 !important;
}

.bgc-darkblue {
  background-color: #2E3D48 !important;
}

.bgc-white {
  background-color: white !important;
}

.bgc-turquoise {
  background-color: #14908E !important;
}

.bgc-darkturquoise {
  background-color: #0B605E !important;
}

.bgc-darkcyan {
  background-color: #0F747214 !important;
}


.fc-blue {
  color: blue !important;
}

.fc-dark-cyan {
  color: #14908E !important;
}

.fc-dark-brown {
  color: #987307 !important;
}

.fc-black {
  color: #000000 !important;
}

.fc-white {
  color: #FFFFFF !important;
}

.fc-darkgray {
  color: #626262 !important;
}

.fc-dark-turquoise {
  color: #0B605E !important;
}

.column2 {
  width: 50%;
}

.column3 {
  width: 33%;
}

.column4 {
  width: 25%;
}

.column5 {
  width: 20%;
}

.column15P {
  width: 15%;
}


.column40P {
  width: 40%;
}

.column45P {
  width: 45%;
}

.column30P {
  width: 30%;
}

.column25P {
  width: 25%;
}

.column10P {
  width: 10%;
}

.column11P {
  width: 11%;
}

.column19P {
  width: 19%;
}

.column5P {
  width: 5%;
}

.column6P {
  width: 6%;
}

.column75P {
  width: 75%;
}

.column35P {
  width: 35%;
}

.column60P {
  width: 60%;
}

.column33P {
  width: 33.33%;
}

.column85P {
  width: 85%;
}

.column55P {
  width: 55%;
}

.column80P {
  width: 80%;
}

.column81P {
  width: 81%;
}

.column70P {
  width: 70%;
}

.column65P {
  width: 65%;
}

.column100P {
  width: 100%;
}

.column50P {
  width: 50%;
}

.column3ARL {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.column4 {
  width: 25%;
}

.column25PARL {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.column49PARL {
  width: 49%;
}

.videoPlayer {
  width: 500px;
  height: 315px;
  max-width: 500px;
}

.column85PARL {
  width: 85%;
  display: flex;
  /*justify-content: center;*/
  /*align-items: center;*/
  flex-direction: column;
}

.anzpacjustifycontent {
  justify-content: flex-end;
}

.input-with-percentage {
  display: flex;
  align-items: center;
}

.percentage-symbol {
  margin-left: 5px;
}

.autowidthButton {
  width: auto;
  background-color: #0d2437 !important;
  color: white !important;
  min-height: 2em !important;
  border-radius: 15px !important;
  /*
  background-color: #93ad6c !important;
  color: black !important;
  */
}

.widthButton {
  width: 90px;
  background-color: #0d2437 !important;
  color: white !important;
  min-height: 2em !important;
  border-radius: 15px !important;
}

.sticky-top-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.checkbox-label {
  display: flex;
  align-items: center
}

.help-container {
  display: flex;
  align-items: center;
}

.core-help-info {
  vertical-align: top;
}

.margin200 {
  margin-left: 200px;
  margin-right: 200px;
}

.padding200 {
  padding-left: 200px;
  padding-right: 200px;
}

.error-message {
  color: red !important;
  margin-top: 5px !important;
  /* font-family: 'Josefin Sans' !important;*/
}

.centered-div {
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
}

.anzpacheading {
  /* color: #8d3c66;*/
  color: #0d2437 !important;
  font-size: 1.1em !important;
  /* font-family: 'Josefin Sans' !important; */
}

.anzpacCriteriaheading {
  font-size: 1.2em !important;
  font-weight: bold !important;
  /* font-family: 'Josefin Sans' !important; */
}

.anzpactext {
  /*  font-family: 'Josefin Sans' !important;*/
  font-family: inherit;
}

.anzpacinput {
  height: 40px !important;
  /*  width: 100% !important;*/
}

.anzpacnumberinput {
  height: 40px !important;
  width: 100% !important;
}

/*.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: center;
}*/

/*p-dropdown.anzpacinput .p-inputtext {
  margin-top: 7px;
}
*/

.anzpaclabel {
  font-weight: bold !important;
  /*  font-size: 15px !important;*/
}

.anzpacspan {
  color: #626262 !important;
}


.anzpach3 {
  font-size: 1.25rem;
  line-height: 1.5625rem;
}

.underline-with-space {
  border-bottom: 6px solid #626262;
  padding-bottom: 5px;
}

.anzpactableheading {
  /* color: #93ad6c !important;*/
  /* font-family: 'Josefin Sans' !important; */
  font-size: 0.8em !important;
}

.anzpactablebigheading {
  font-size: 1.0em !important;
}

.anzpacTableHeaderBg {
  background-color: #0d2437;
  color: white;
}

.p-confirm-dialog {
  font-size: 1.2em !important;
  font-family: inherit !important;
}

.p-steps {
  background-color: white !important;
  width: 100%;
  padding: 5px;
}

.p-steps ul {
  padding-left: 10px;
  margin-bottom: 5px;
}

.p-steps-title {
  font-size: 0.9em !important;
  /* font-family: 'Josefin Sans' !important; */
  font-weight: bold;
  color: black !important;
  padding: 3px 5px;
  margin-left: 2px !important;
  min-width: 5em;
  word-wrap: break-word;
  white-space: pre-line;
}

.p-steps-number {
  background-color: #93ad6c !important;
  color: white !important;
  /*
    background-color: #93ad6c !important;
    */
}

.p-steps .p-steps-item {
  width: min-content;
}

.freeTextEditable {
  width: 100%;
  height: 80px;
  resize: none;
}

.freeTextNonEditable {
  width: 100%;
  height: 40px;
  resize: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  background-color: #0d2437 !important;
  color: white !important;
  /*
  background-color: #93ad6c !important;
  */
  border-radius: 15px !important;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  background-color: #0d2437 !important;
  color: white !important;
  /*
  background-color: #93ad6c !important;
  */
  border-radius: 15px !important;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  background-color: transparent !important;
  border-radius: 15px !important;
}

.p-steps.completed-1 .p-steps-item:nth-child(1) .p-menuitem-link .p-steps-number,
.p-steps.completed-2 .p-steps-item:nth-child(2) .p-menuitem-link .p-steps-number,
.p-steps.completed-3 .p-steps-item:nth-child(3) .p-menuitem-link .p-steps-number,
.p-steps.completed-4 .p-steps-item:nth-child(4) .p-menuitem-link .p-steps-number,
.p-steps.completed-5 .p-steps-item:nth-child(5) .p-menuitem-link .p-steps-number,
.p-steps.completed-6 .p-steps-item:nth-child(6) .p-menuitem-link .p-steps-number,
.p-steps.completed-7 .p-steps-item:nth-child(7) .p-menuitem-link .p-steps-number,
.p-steps.completed-8 .p-steps-item:nth-child(8) .p-menuitem-link .p-steps-number {
  background: #b00020 !important;
}

.contolinvalid {
  border: 3px solid #b00020;
}

.rowSelecetd {
  border: 2px solid black;
}

.rowNotSelecetd {
  color: darkgray
}

.loadingcenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loadingcenterLabel {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -50%);
}

.loadingcenterimage {
  position: fixed;
  top: 25%;
  left: 45%;
  transform: translate(-25%, -45%);
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background-color: black;
  color: white;
  min-height: 75px;
  min-width: 150px;
  text-align: center;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: black;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  /* font-size: 1.2em;*/
  min-height: 75px;
  text-align: center;
  min-width: 150px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: #9D9D9D;
  color: black;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-right: 5px;
  min-height: 75px;
  min-width: 150px;
  text-align: center;
}

.config-admin-page {

  & dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.member-page {

  & dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 5px;
  }

  .p-checkbox-label {
    font-size: 0.9em;
    line-height: 1em;
  }

  .text-label {
    padding: 2px;
    font-size: 0.9em;
    font-weight: bold;
    font-family: inherit;
    line-height: 1em;
  }

}

.anzpac-member-report {
  & p-dropdown .p-inputtext {
    margin-top: 7px;
  }
}

.anzpacbt-1 {
  border-top: 1px solid #626262 !important;
}

.text {
  font-size: 4.5em !important;
  text-align: left !important;
  max-width: 90% !important;
  /*  padding-left:50px;
  padding-right:50px;*/
}

.subtext {
  font-size: 2em !important;
  text-align: left !important;
  max-width: 90% !important;

}

.subtext2 {
  font-size: 2.5vw !important;
  text-align: left !important;
  max-width: 80% !important;
}

.subtext3 {
  font-size: 2.5vw !important;
  text-align: left !important;
  max-width: 70% !important;
}

.fontsizegov {
  font-size: 1em !important;
}

@media only screen and (max-width: 1600px) {
  body {
    font-size: 15px !important;
  }
}

@media (max-width: 1200px) {
  .text {
    font-size: 3.5em !important;
    text-align: left !important;
    max-width: 90% !important;
    /*  padding-left:50px;
  padding-right:50px;*/
  }

  .subtext {
    font-size: 1.5em !important;
    text-align: left !important;
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 1024px) {
  body {
    font-size: 14px !important;
  }

  .body-container {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }

  .left-header-padding {
    width: 0px;
  }

  .column2 {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  body {
    font-size: 14px !important;
  }

  .fontsizegov {
    font-size: 16px !important;
  }

  .p-menubar .p-menubar-button {
    color: black;
    width: 6em;
  }

  .p-menubar .p-menubar-root-list {
    background: white;
  }
}

@media (min-width: 768px) {


  .navbar {
    height: 80px;
  }

  .nav-item {
    padding-top: 1em;
    padding-right: 25px;
  }

  .squares {
    border: solid 5px white;
    border-radius: 15px;
  }

  .footer {
    font-size: .8em;
  }
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 13px !important;
  }

  p {
    font-size: 13px;
  }



  .rm-widget-container {
    width: 100%;
  }

  .iframeborder {
    border: 8px solid #14908E;
    margin-bottom: 1px;
    border-radius: 0px;
  }

  .navbar {
    height: 60px;
  }

  .nav-item {
    line-height: 1;
    padding-top: 5px;
  }

  .btm-link {
    padding-bottom: 20px;
  }

  .dropdown-item {
    line-height: 1.5;
  }

  .squares {
    border: 4px solid white;
    border-width: 1px 0 1px 0px;
  }

  .footer {
    font-size: .6em;
  }
}

@media only screen and (max-width: 640px) {
  body {
    font-size: 12px !important;
  }

  p {
    font-size: 12px;
  }

  .column3 {
    width: 50%;
  }

  .column4 {
    width: 50%;
  }
}

@media (max-width: 415px),
(max-height: 415px) {
  .column3 {
    width: 100%;
  }

  .column4 {
    width: 100%;
  }
}

@media only screen and (max-width: 360px) {
  body {
    font-size: 11px !important;
  }

  p {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1024px) {
  .column85PARL {
    width: 100%;
    margin-top: 8px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .column85PARL {
    width: 100%;
    margin-top: 8px !important;
  }
}



@media only screen and (max-width: 1024px) {
  .column3ARL {
    width: 100%;
  }
}


@media only screen and (max-width: 600px) {
  .column3ARL {
    width: 100%;
  }
}


@media only screen and (max-width: 1024px) {
  .column25PARL {
    width: 50%;
    margin-top: 8px !important;
    padding: 8px !important;
  }
}


@media only screen and (max-width: 600px) {
  .column25PARL {
    width: 100%;
    margin-top: 8px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .column49PARL {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .column49PARL {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .videoPlayer {
    width: 355px;
    height: 315px;
  }
}


.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: white;
  border-color: transparent;
  background: #343e48;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 0.8125rem;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  color: white;
  border-color: transparent;
  background: #343e48;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 0.8125rem;
  padding-right: 5px;
}

.headercontainer {
  display: flex;
  flex-wrap: wrap;
  /*  align-items: center;*/
  /*  justify-content: center;*/
}

.left-div,
.right-div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 50%;
}

.right-div img {
  width: 100%;
  height: auto;
}

.headerheading {
  text-align: left !important;
  max-width: 90%;
  font-size: 5vw;
  color: white !important;
  font-weight: 700 !important;
  font-family: 'Roboto Condensed' !important;
  word-wrap: break-word !important;
}

.headersubheading {
  text-align: left !important;
  color: white !important;
  font-family: Arial !important;
  font-weight: 700 !important;
  word-wrap: break-word !important;
}

.heading {
  font-family: 'Roboto Condensed' !important;
  /*font-family: Algerian !important;*/
  font-weight: bold !important;
}

.subheading {
  font-weight: bold;
}

.bannercontainer {
  display: flex !important;
  flex-wrap: wrap !important;
}

.bannerheader {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #0B605E;
  color: white;
  /*  padding :10px;*/
}

.bannerimage {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.img {
  max-width: 100% !important;
  height: auto !important;
}

.contentheader {
  font-size: 1.8em !important;

}

/* @media (max-width: 768px) {
  .text {
    font-size: calc(3vw + 3vh) !important;
    text-align: left !important;
  }
}

@media (max-width: 768px) {
  .subtext {
    font-size: calc(1.8vw + 1.8vh) !important;
    text-align: left !important;
  }
} */

@media (max-width: 768px) {
  .subtext2 {
    font-size: calc(1.8vw + 1.8vh) !important;
    text-align: left !important;
  }
}

@media (max-width: 768px) {
  .subtext3 {
    font-size: calc(1.8vw + 1.8vh) !important;
    text-align: left !important;
  }
}

@media (max-width: 768px) {
  .contentheader {
    font-size: 1.3em !important;
  }
}

.fontsize {
  font-size: 1em !important;
}

.govheaderfontsize {
  font-size: 1em !important;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .fontsize {
    font-size: 15px !important;
    /* margin-left: 8px !important;
    margin-right: 8px !important; */
  }
}


@media (max-width: 599px) {
  .fontsize {
    font-size: 16px !important;
    /* margin-left: 8px !important;
    margin-right: 8px !important; */
  }
}





/* @media (min-width: 600px) and (max-width: 1024px) {
  .fontsizegov {
    font-size: 15px !important;
  }
}


@media (max-width: 599px) {
  .fontsizegov {
    font-size: 16px !important;
  }
} */


.column5ARL {
  width: 20%;
}

@media (max-width: 600px) {
  .column5ARL {
    width: 100% !important;
    margin: 8px !important;
  }
}

.caption {
  margin-bottom: 8px;
  font-size: 16px;
}

.column15PARL {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1024px) {
  .column15PARL {
    width: 100%;
    margin-bottom: 8px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .column15PARL {
    width: 100%;
    margin-bottom: 8px !important;
  }
}

.round-button {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

@media (max-width: 768px) {
  .govheader {
    text-align: left !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
}

.footerfont {
  font-size: 1.1em !important;
  font-weight: bold !important;
}


/* @media (min-width: 600px) and (max-width: 1024px) {
  .footerfont {
    font-size: 15px !important;
  }
}


@media (max-width: 599px) {
  .footerfont {
    font-size: 12px !important;
  }
} */



.p-menubar .p-submenu-list {
  margin-top: 15px;
}

.p-menubar .p-menuitem {
  margin-left: 30px;
  font-size: 16px;
  font-weight: bold;
}

.p-panelmenu .p-menuitem-text {
  font-size: 16px !important;
  font-weight: bold !important;
  /*  margin-left: 80px;
  margin-right: 80px;*/
}

.member-page {
  .p-menubar .p-menuitem {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
}

.parentfont {
  font-size: 16px !important;
}

.h2section {
  margin-bottom: 0.5rem !important;
}

.psection {
  margin-top: 0.5rem !important;
}


@media (max-width: 768px) {
  .h2section {
    margin-bottom: 0.75rem !important;
  }

  .psection {
    margin-top: 0.75rem !important;
  }
}

.ribbon-members__logo {
  display: flex;
  max-width: 15vw;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.ribbon-members__img {
  max-width: 77px;
  max-height: 70px;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}


.headercontainer {
  display: flex !important;
  width: 100% !important;
}

.leftheader,
.rightheader {
  flex: 1 !important;
  max-width: 50%;
  /*  justify-content: center !important;*/
}

.leftheader {
  background-color: #0B605E !important;
  color: white !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  /*  align-items: center !important;*/
  /* padding: 20px;*/
}

.leftheader h1,
.leftheader h2 {
  text-align: left !important;
}



.rightheader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rightheader img {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover !important;
}

@media (max-width: 1024px) {
  .headercontainer {
    flex-direction: column !important;
  }

  .leftheader,
  .rightheader {
    flex: none !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .rightheader img {
    width: 100% !important;
    height: 100% !important;
  }
}

.box {
  background-color: white;
}


@media only screen and (max-width: 1200px) {
  .box {
    width: 100%;
  }
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.desktop-padding {
  padding-left: 15px;
  padding-right: 15px;
}

/*@media (min-width: 1024px) {
  .desktop-padding {
    padding-left: calc((100% - 89%) / 2);
  }
}*/


@media (min-width: 1024px) {
  .desktop-padding {
    padding-left: 7vw !important;
    padding-right: .5vw !important;
  }
}



@media (min-width: 1024px) {
  .flex-container {
    display: flex;
  }
}

@media (max-width: 1023px) {
  .flex-container {
    display: inline;
  }
}


.popup {
  z-index: 1002 !important;
  transform-origin: center top !important;
  top: 51.7656px !important;
  left: 0 !important;
  width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.241914);
}

.headinglineheight {
  line-height: 0.9em !important;
}

.partnerimg {
  max-width: 90% !important;
  height: auto !important;
}

ul.checklist li::before {
  content: "✔";
  color: black;
  font-weight: bold;
  margin-right: 10px;
}


@media (max-width: 768px) {
  .partnerimg {
    max-width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
}

@media (max-width: 480px) {
  .partnerimg {
    max-width: 70% !important;
    margin-left: 15% !important;
    margin-right: 15% !important;
  }
}

.h3gov {
  color: black !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.resourceheight {
  height: 33em;
}

.resourcedesheight {
  height: 29em;
}

@media (max-width: 729px) {
  .resourceheight {
    height: 36em !important;
  }

  .resourcedesheight {
    height: 32em !important;
  }
}

@media (max-width: 559px) {
  .resourceheight {
    height: 46em !important;
  }

  .resourcedesheight {
    height: 42em !important;
  }
}

@media (max-width: 416px) {
  .resourceheight {
    height: 34em !important;
  }

  .resourcedesheight {
    height: 30em !important;
  }
}

@media (max-width: 369px) {
  .resourceheight {
    height: 47em !important;
  }

  .resourcedesheight {
    height: 43em !important;
  }
}

@media (max-width: 361px) {
  .resourceheight {
    height: 64em !important;
  }

  .resourcedesheight {
    height: 60em !important;
  }
}
