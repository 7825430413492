
h1 {
  font-size: 2em;
  /* font-family: ff-good-web-pro, sans-serif; */
  font-family: arial, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h2 {
  font-size: 1.5em;
  /* font-family: ff-good-web-pro, sans-serif; */
  font-family: arial, sans-serif;
  font-weight: 600;
  font-style: normal;
 
}

h3 {
  font-size: 1em;
  /* font-family: ff-good-web-pro, sans-serif; */
  font-family: arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* General formatting and alignment */

.flex-wrap {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
  align-items: flex-start;
  color: inherit;
  background: transparent;
  background-color: transparent;
}

.flex-inline {
  display: inline-flex;
  color: inherit;
  background: transparent;
  background-color: transparent;
}

.flex-fill {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  max-width: 100% !important;
  color: inherit;
  background: transparent;
  background-color: transparent;
}

.hidden {
  display: none !important;
}

.centroid {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.r-centroid {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.r-vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.right {
  right: 0px;
  margin-left: auto !important;
  margin-right: 0px !important;
  float: right;
}

.left {
  left: 0px;
  margin-left: 0px !important;
  margin-right: auto !important;
  float: left;
}

.top {
  vertical-align: top;
  top: 0px;
  position: relative;
}

.middle {
  vertical-align: middle;
  align-items: center;
  align-content: center;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.bottom {
  vertical-align: bottom;
  bottom: 0px;
  position: relative;
}

.font-bold {
  font-weight: 700 !important;
}

.font-xlarge {
  font-size: x-large !important;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* truncate to 2 lines */
  -webkit-line-clamp: 2;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* truncate to 4 lines */
  -webkit-line-clamp: 4;
}

.justify {
  justify-content: flex-end;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
  padding-left: 0.5em;
}

.text-right {
  text-align: right;
  padding-right: 0.5em;
}

.text-underline {
  text-decoration: underline;
}
.text-label {
  padding: 2px;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
}

.text-value {
  padding: 2px;
  font-size: 1em;
  font-weight: normal;
  font-family: inherit;
  overflow: auto;
}
.text-multiline {
  word-wrap: break-word;
  white-space: pre-line;
  flex-grow: 1 !important;
}

.fit-content {
  align-items: start;
  align-content: start;
}

.sgl-line {
  line-height: 1em;
}

.dbl-line {
  line-height: 2em;
}

.p1 {
  padding: 2px !important;
}

.p2 {
  padding: 5px !important;
}

.p3 {
  padding: 10px !important;
}

.p4 {
  padding: 15px !important;
}

.p5 {
  padding: 24px !important;
}

.pl-1 {
  padding-left: 2px !important;
}

.pl-2 {
  padding-left: 5px !important;
}

.pr-1 {
  padding-right: 2px !important;
}

.pr-2 {
  padding-right: 5px !important;
}

.pt-3 {
  padding-top: 10px !important;
}

.pb-3 {
  padding-bottom: 10px !important;
}

.mt-1 {
  margin-top: 2px !important;
}

.mt-2 {
  margin-top: 5px !important;
}

.mt-3 {
  margin-top: 10px !important;
}

.mt-4 {
  margin-top: 15px !important;
}

.mt-7 {
  margin-top: 30px !important;
}


.mt-8 {
  margin-top: 45px !important;
}



.mb-1 {
  margin-bottom: 2px !important;
}

.mb-2 {
  margin-bottom: 5px !important;
}

.mb-3 {
  margin-bottom: 10px !important;
}

.mb-4 {
  margin-bottom: 15px !important;
}

.mb-8 {
  margin-bottom: 45px !important;
}

.ml-1 {
  margin-left: 2px !important;
}

.ml-2 {
  margin-left: 5px !important;
}

.ml-3 {
  margin-left: 10px !important;
}

.ml-4 {
  margin-left: 15px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.mr-1 {
  margin-right: 2px !important;
}

.mr-2 {
  margin-right: 5px !important;
}

.mr-3 {
  margin-right: 10px !important;
}

.mr-4 {
  margin-right: 15px !important;
}

.mr-5 {
  margin-right: 20px !important;
}


.m1 {
  margin: 2px !important;
}

.m2 {
  margin: 5px !important;
}

.m3 {
  margin: 10px !important;
}

.m4 {
  margin: 15px !important;
}

.b1 {
  border: 1px solid #E5E5E5 !important;
}
.bb1 {
  border: 1px solid #000000 !important;
}
.btb-1 {
  border-top: 1px solid #000000 !important;
}
.bbb-1 {
  border-bottom: 1px solid #000000 !important;
}
.brb-1 {
  border-right: 1px solid #000000 !important;
}
.blb-1 {
  border-left: 1px solid #000000 !important;
}

.bt-1 {
  border-top: 1px solid #E5E5E5 !important;
}

.bl-1 {
  border-left: 1px solid #E5E5E5 !important;
}

.br-1 {
  border-right: 1px solid #E5E5E5 !important;
}

.br-5 {
  border-right: 5px solid #E5E5E5 !important;
}

.bl-5 {
  border-left: 5px solid #E5E5E5 !important;
}

.bb-5 {
  border-bottom: 5px solid #E5E5E5 !important;
}

.bt-5 {
  border-top: 5px solid #E5E5E5 !important;
}

.bb-1 {
  border-bottom: 1px solid #E5E5E5 !important;
}

.brad1 {
  border-radius: 2px;
}

.brad2 {
  border-radius: 5px;
}

.brad3 {
  border-radius: 10px;
}

.brad4 {
  border-radius: 15px;
}

.brad-tr1 {
  border-top-right-radius: 2px;
}

.brad-tr2 {
  border-top-right-radius: 5px;
}

.brad-tr3 {
  border-top-right-radius: 10px;
}

.brad-tr4 {
  border-top-right-radius: 15px;
}

.brad-tl1 {
  border-top-left-radius: 2px;
}

.brad-tl2 {
  border-top-left-radius: 5px;
}

.brad-tl3 {
  border-top-left-radius: 10px;
}

.brad-tl4 {
  border-top-left-radius: 15px;
}

.brad-br1 {
  border-bottom-right-radius: 2px;
}

.brad-br2 {
  border-bottom-right-radius: 5px;
}

.brad-br3 {
  border-bottom-right-radius: 10px;
}

.brad-brl4 {
  border-bottom-right-radius: 15px;
}

.brad-bl1 {
  border-bottom-left-radius: 2px;
}

.brad-bl2 {
  border-bottom-left-radius: 5px;
}

.brad-bl3 {
  border-bottom-left-radius: 10px;
}

.brad-bl4 {
  border-bottom-left-radius: 15px;
}

.brad-br4 {
  border-bottom-right-radius: 15px;
}

.w-fit-content {
  width: fit-content;
}

.w2 {
  width: 2em !important;
  min-width: 2em !important;
}

.w3 {
  width: 3em !important;
  min-width: 3em !important;
}

.w4 {
  width: 4em !important;
  min-width: 4em !important;
}

.w5 {
  width: 5em !important;
  min-width: 5em !important;
}

.w6 {
  width: 6em !important;
  min-width: 6em !important;
}

.w7 {
  width: 7em !important;
  min-width: 7em !important;
}

.w8 {
  width: 8em !important;
  min-width: 8em !important;
}

.w9 {
  width: 9em !important;
  min-width: 9em !important;
}

.w10 {
  width: 10em !important;
  min-width: 10em !important;
}

.w12 {
  width: 12em !important;
  min-width: 12em !important;
}

.w15 {
  width: 15em !important;
  min-width: 15em !important;
}

.w16 {
  width: 16em !important;
  min-width: 16em !important;
}

.w17 {
  width: 17em !important;
  min-width: 17em !important;
}

.w18 {
  width: 18em !important;
  min-width: 18em !important;
}

.w20 {
  width: 20em !important;
  min-width: 20em !important;
}

.w24 {
  width: 24em !important;
  min-width: 24em !important;
}

.w40 {
  width: 40em !important;
  min-width: 40em !important;
}
